<template>
  <q-form
    ref="confirmForm"
    class="login"
    @submit="submitForm"
  >
    <q-card-section>
      <div class="q-pa-sm">
        <h5 class="q-mb-none q-mt-xs text-weight-bold">
          Confirm Account
        </h5>
      </div>
    </q-card-section>

    <q-card-section
      v-if="!!serverErrorMessage"
      class="q-py-none"
    >
      <div
        class="q-px-md q-py-sm text-red"
        inset
      >
        {{ serverErrorMessage }}
      </div>
    </q-card-section>

    <q-card-section>
      <div class="q-pa-sm q-gutter-y-xs column">
        <q-input
          v-model="uid"
          label="Confirmation Code"
          dense
          :rules="[val => !!val || 'Field is required']"
        />

        <q-btn
          type="submit"
          color="primary"
          no-caps
          :loading="loading"
        >
          Confirm Account
        </q-btn>
      </div>
    </q-card-section>

    <q-separator />

    <q-card-actions align="between">
      <q-btn
        to="/auth/register"
        color="secondary"
        flat
        no-caps
      >
        Register
      </q-btn>
      <q-btn
        to="/auth/login"
        color="secondary"
        flat
        no-caps
      >
        Log In
      </q-btn>
    </q-card-actions>
  </q-form>
</template>

<script>
import { AuthActionTypes } from 'src/modules/auth/store/action-types';
import { serverErrorMixin } from 'src/mixins/server_error.mixin';
import ApiService from 'src/services/api.service';
import { TokenService } from 'src/services/storage.service';

export default {
  mixins: [serverErrorMixin],
  props: {
    uid: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.getters['auth/isLoggedIn']) {
        next('/');
      }
    });
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    },
  },
  watch: {
    isLoggedIn: function(new_val) {
      if (new_val) {
        this.$router.push('/account/home');
      }
    },
  },
  created() {
    if (this.uid) {
      this.submitForm();
    }
  },
  methods: {
    async submitForm() {
      const { uid } = this;
      console.log('form submitted', uid);
      this.loading = true;

      try {
        const confData = await ApiService.get('/jwt/auth/confirm/' + uid);
        console.log('got back ', confData);

        if (confData.data) {
          TokenService.saveToken(confData.data.access_token);
          TokenService.saveRefreshToken(confData.data.refresh_token);
          ApiService.setHeader();

          // dispatch
          this.$store.dispatch(`auth/${AuthActionTypes.AuthLoginSuccess}`, confData.data).then(() => {
            this.$router.push({ name: 'accountHome' });
          });
        }
      } catch (err) {
        console.log('cautght err', err);

        if (err.response) {
          console.log('caught err', err.response);
          this.processErrorData(err.response.data);

          this.$refs.confirmForm.validate();
        } else {
          console.log('Error: ', err.message);
          throw err;
        }

        return false;
      } finally {
        this.loading = false;
      }

      // this.$store
      //   .dispatch(`auth/${AuthActionTypes.AuthLoginRequest}`, {
      //     uid,
      //   })
      //   .then(() => {
      //     console.log('in auth then ', this.$route.query);
      //     this.loading = false;
      //     if(this.$route.query.nextUrl != null){
      //       this.$router.push(this.$route.query.nextUrl);
      //     } else {
      //       this.$router.push("/");
      //     }
      //   })
      //   .catch(err => {
      //     this.loading = false;

      //     this.serverErrorMessage = err.data.message;
      //     if (err.data.errors) {
      //       this.serverErrors = err.data.errors;
      //     }

      //     this.$refs.loginForm.validate();
      //   });
    },
  },
};
</script>
